// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line
// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import {document} from "postcss";
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();
const throttle = (fn, throttleTime) => {
  let start = -Infinity;
  let cachedResult;

  return function() {
    const end = Date.now();

    if (end - start >= throttleTime) {
      start = end;
      cachedResult = fn.apply(this, arguments);
    }

    return cachedResult;
  };
}
/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({scrollTop: $target.offset().top - 50}, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();

  /**
   * Counter for flexible section Numbers
   */
  let isIntersecting = true;
  const aboutUsObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting && isIntersecting) {
        $('.js-number').each(function () {

          let size = $(this).text().split('.')[1]
            ? $(this).text().split('.')[1].length
            : 0;
          $(this)
            .prop('Counter', 0)
            .animate(
              {
                Counter: $(this).text(),
              },
              {
                duration: 2000,
                step: function (func) {
                  $(this).text(parseFloat(func).toFixed(size));
                },
              }
            );
        });
        isIntersecting = false;
      }
    });
  }, {threshold: 1});
  $(".flexible-numbers").each(function (elem) {
    aboutUsObserver.observe($(".flexible-numbers")[elem]);
  });
  /**
   * Blog share button
   */
  $(document).on('click', '.js-share', function (e) {
    let popup = $(this).next('.js-share-popup');
      $('.js-share-popup').each(function () {
        if ($(this).hasClass('is-active')) {
          $(this).removeClass('is-active');
        }
      })
      popup.addClass('is-active');
  });
  $(document).on('click', function (e) {
    if(!e.target.closest(".button-share")){
      $('.js-share-popup').each(function () {
        if ($(this).hasClass('is-active')) {
          $(this).removeClass('is-active');
        }
      })
    }
  });
  /**
   * Wishlist popup
   */
  $(document).on('click', '.js-wl-button', function () {
    $('.js-wishlist').toggleClass('is-active');
    $('.wishlist').toggleClass('active-wishlist');
  })
  $(document).on('click', '.js-close-wishlist', function () {
    $('.js-wishlist').toggleClass('is-active');
    $('.wishlist').toggleClass('active-wishlist');
  })
  const closeWishlist = () => {
    $('.js-wishlist').removeClass('is-active');
    $('.wishlist').removeClass('active-wishlist');
    $('body').removeClass('is-blocked');
  }
  $(document).on('click',function (e) {
    if(e.target.classList.contains('wishlist__mob-popup') || e.currentTarget == $('.js-close-wishlist')){
      closeWishlist();
    }
  });
  /**
   * Cart
   */
  var timeout;
  $(document).on('change input', '.woocommerce-cart-form .product-remove .remove', function(){
    if ( timeout !== undefined ) clearTimeout( timeout );
    timeout = setTimeout(function() {
      $('[name=update_cart]').trigger('click'); // trigger cart update
    }, 500 );
  });

  $(document).on('tinvwl_wishlist_ajax_response', 'body', function () {
    const ajaxFunction = () => {
      setTimeout(function () {
        $.ajax({
          type: 'POST',
          url: ajax_object.ajax_url,
          data: {
            action: 'mode_theme_update_mini_cart',

          },
          beforeSend: function () {
            $('.wishlist__wrapper').addClass('is-loading');
          },
          success: function (response) {
            // $(document).ajaxStop(function() {
              $('.wishlist__mob-popup').html(response);
            // })
            if(window.innerWidth >= 1024) {
                $("html, body").animate({scrollTop: 0}, "slow");
            }else {
              if(!$('.menu-icon').hasClass('is-active')){
                $('.menu-icon').click();
              }
            }
              if(!$('.wishlist').hasClass('active-wishlist')){
                $('.wishlist').addClass('active-wishlist');
              }
              if(!$('.wishlist__wrapper').hasClass('is-active')){
                $('.wishlist__wrapper').addClass('is-active');
              }
              $('.wishlist__wrapper').removeClass('is-loading');
          },
        });
      },400);
    };
    // setTimeout(function () {
        ajaxFunction();
    // }, 2000);
  })
});
// Document ready end
$('.js-testimonials').slick({
  dots: true,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  rows: 0,
});
$('.js-event').click('.js-event-toggler', function () {
  $(this).toggleClass('is-active');
  $(this).find('.js-event-bottom').slideToggle();
})

$('.js-dropdown-title').click(function (j) {
  if ($(this).hasClass('is-active')) {
    $(this).closest('.js-dropdown').find('.js-dropdown-text').slideUp(500);
    $(this).removeClass('is-active');
  } else {
    $('.dropdown').each(function () {
      $(this).find('.js-dropdown-title').removeClass('is-active');
      $(this).find('.js-dropdown-text').slideUp(500);
    })
    $(this).closest('.js-dropdown').find('.js-dropdown-text').slideDown(500);
    $(this).addClass('is-active');
  }
});

const topBarReservedSpace = () => {
  if ($(window).innerWidth() > 640) {
    let topBar = $('.header-top-bar .under-bar').innerHeight();
    if (topBar) {
      $('.header-top-bar').css("--top-bar-height", topBar + 'px');
    } else {
      $('.header-top-bar').css("--top-bar-height", '0px');
    }
  } else {
    $('.header-top-bar').css("--top-bar-height", '0px');
    return false;
  };
}
// Team popup
$(document).on('click', '[data-popup-open]', function (e) {
  let targeted_popup_class = $(this).attr('data-popup-open');
  $('[data-popup="' + targeted_popup_class + '"]').fadeIn(350);
  $('body').css("overflow", 'hidden');
  e.preventDefault();
});
$(document).on('click', '.popup__wrapper', function (e) {
  if(e.target.classList.contains('popup__wrapper')){
    $(this).closest('.popup').fadeOut(350);
    $('body').css("overflow", 'auto');
  }
});
$(document).on('click', '[data-popup-close]', function (e) {
  let targeted_popup_class = $(this).attr('data-popup-close');
  $('[data-popup="' + targeted_popup_class + '"]').fadeOut(350);
  $('body').css("overflow", 'auto');
  e.preventDefault();
});

$(document).on('click', '.popup .ajax_add_to_cart', function (e) {
  let targeted_popup_class = 'popup-' + $(this).attr('data-product_id');
  setTimeout(function () {
    $('[data-popup="' + targeted_popup_class + '"]').fadeOut(350);
    $('body').css("overflow", 'auto');
  }, 1200)
});
$(document).on('click', '.copy-link', function (e) {
  navigator.clipboard.writeText($(this).attr('data-link'))
});
$(window).on('load', topBarReservedSpace);
$(window).on('resize', topBarReservedSpace);
/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
  if (window.scrollY > 150) {
    $('.woocommerce-shop .js-top').addClass('is-active');
  } else {
    $('.woocommerce-shop .js-top').removeClass('is-active');
  }
});
